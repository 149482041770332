import React from "react"
import { graphql } from "gatsby"
import Layout from "../modules/layout"
import SEO from "../modules/seo"
import BigHeader from "../components/bigHeader"
import GeneralContent from "../components/generalContent"
import QuickSelect from "../components/quickSelect"
import JoinJobs from "../components/joinJobs"

const Jobs = ({ data: { allContentfulStandardseite } }) => {
  const content = allContentfulStandardseite.nodes[0]

  return (
    <Layout theme="lieblingsplatz">
      <SEO title={content.title} />
      <BigHeader 
        image={content.headerImage.fluid} 
        alt={content.headerImage.description} 
        additionalClass="home"
        title={content.title}
        subTitle={content.subTitle}
      />
      <QuickSelect root={true} />
      <GeneralContent
        additionalClass="color_invert"
        title={content.title}
        content={content.content ? content.content.json : null}
        textCentered={false}
      />
      <JoinJobs />
    </Layout>
  )
}

export const pageQuery = graphql`
query jobsQuery {
  allContentfulStandardseite(filter: {type: {eq: "Jobs"}}) {
    nodes {
      content {
        json
      }
      id
      title
      subTitle
      headerImage {
        fluid(maxWidth: 1600 quality: 80) {
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
          aspectRatio
        }
        description
      }
    }
  }
}
`

export default Jobs

import React from "react"
import { Container } from "react-bootstrap"
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import { navigate } from "gatsby"

const QuickSelect = ({ linkToZimmer, theme, root }) => {
  let bookingPage = ''
  switch (theme) {
    case 'meinsbur':
      bookingPage = '/hotel-meinsbur-bendestorf/buchen'
      break;
    case 'strandhotel':
      bookingPage = '/strandhotel-st-peter-ording/buchen'
      break;
    case 'insellodge':
      bookingPage = '/hotel-bohlendorf-ruegen/buchen'
      break;
    case 'strandperle':
      bookingPage = '/hotel-strandperle-travemuende/buchen'
      break;
    case 'tirol':
      bookingPage = '/hotel-tirol/buchen'
      break;
    case 'braunfels':
      bookingPage = '/schlosshotel-braunfels/buchen'
      break;
    default:
      break;
  }

  const options = [
    { value: '/hotel-meinsbur-bendestorf/buchen/', label: 'Bendestorf · Lüneburger Heide' },
    { value: '/strandhotel-st-peter-ording/buchen/', label: 'St. Peter-Ording · Nordsee' },
    { value: '/hotel-bohlendorf-ruegen/buchen/', label: 'Rügen · Ostseeinsel' },
    { value: '/hotel-strandperle-travemuende/buchen/', label: 'Travemünde · Ostsee' },
    { value: '/hotel-tirol/buchen/', label: 'Zell am Ziller - Tirol' },
    { value: '/schlosshotel-braunfels/buchen/', label: 'Braunfels · Lahntal' }
  ]

  const _onSelect = (val) => {
    navigate(val.value)
  }

  if (root) {
    return (
      <section className="component quickSelect root" id="hotel_overview">
        <Container fluid className="d-flex flex-column flex-md-row justify-content-center">
          <Dropdown options={options} onChange={_onSelect} placeholder="Wähle Dein Lieblingsplatz" />
          <button className="btn ml-md-3 mt-3 mt-md-0 mb-3 mb-md-0">Verfügbarkeit prüfen</button>
        </Container>
      </section>
    )
  } else {
    return (
      <section className="component quickSelect">
        <Container fluid>
          <div 
            className="skd-widget" 
            data-skd-widget="check-availability" 
            data-skd-send-to-groups="A" 
            data-skd-listen-to-groups="B" 
            data-skd-offer-list-page={bookingPage}
            data-skd-show-language="false"
            data-skd-show-roombox="false"
            data-skd-show-header="false"
            data-skd-language-code="de"
            data-skd-show-currency="false"
            data-skd-show-my-bookings="false"
          >
            {/* <div style={{width: '100%', minHeight: 50, textAlign: 'center'}}>
              <img src="https://static.seekda.com/assets/images/skd_spinner.gif" alt="Loading" />
              <noscript>Your browser doesn't support JavaScript or it has been disabled. To use the booking engine, please make sure to get JavaScript running.</noscript>
            </div> */}
          </div>
        </Container>
      </section>
    )
  }
}

export default QuickSelect

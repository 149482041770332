import React, { Component } from "react"
import { Container } from "react-bootstrap"

class JoinJobs extends Component {

  render() {
    return (
      <section className="component joinJobs">
        <Container fluid>
          <iframe title="joinJobs" frameBorder="0" src="https://preview.lieblingsplatz-hotels.de/jobs.html" height="700" width="100%"></iframe>
        </Container>
      </section>
    )
  }
}

export default JoinJobs

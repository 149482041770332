import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"

const BigHeader = ({ additionalClass, image, alt, title, subTitle, strer }) => (
  <section className={`component bigHeader ${additionalClass}`}>
    <Image fluid={image} alt={alt} className="img-fluid" />
    {image.file && <img src={image.file.url} className="img-fluid" alt="preview" />}
    {(image.fluid) && <Image fluid={image.fluid} alt={alt} className="img-fluid" />}
    {title && 
      <div className="titleWrapper color_invert">
        <h1 className="handwritten h2">{title}</h1>
        <h2 className="h4 mb-0">{subTitle}</h2>
      </div>
    }
    {strer &&
      <div className="badgeWrapper color_white">
        <a href={strer.link}>
          <span className="text-uppercase">{strer.prefix}</span>
          <span className="h3">{strer.message}</span>
          <span>{strer.suffix}</span>
        </a>
      </div>
    }
  </section>
)

export default BigHeader
